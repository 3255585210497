<template>
  <div>
    <div class="block">
      <div class="block__col">
        <template v-for="n in 2">
          <div :key="n">
            {{ props[n - 1].name }}:
            {{ props[n - 1].value }}
          </div>
        </template>
      </div>

      <div class="block__col">
        <template v-for="n in 3">
          <div :key="n + 1">
            {{ props[n + 1].name }}:
            {{ props[n + 1].value }}
          </div>
        </template>
      </div>

      <div class="block__col">
        Цена<span class="note-star">*</span>
        <br><span class="price">{{ printPrice }} руб.</span>
      </div>
    </div>

    <div class="note">
      <span class="note-star">*</span>
      Примечание: стоимость указана без учета транспортировки и упаковки.
      В стоимость входит: НДС 18%; защитная пленка с двух сторон; стандартный цвет.
    </div>

    <p class="print">
      <br>Для заказа сэндвич-панелей свяжитесь с нами по номеру <b>+7 (495) 197-65-02</b>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ResultBlock',
  props: {
    props: {
      type: Array,
      required: true,
      default: () => [],
    },
    price: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    printPrice() {
      return this.price.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
  .block {
    background: #FFFFFF;
    padding: 19px;
    margin: 40px 0 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 20px;
    justify-content: space-between;
    line-height: 1.5;

    @media print {
      margin: 0 0 15px;
      padding: 0;
    }

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 641px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .note {
    font-size: 14px;

    &-star {
      color: #BF0A29;
    }

    @media (max-width: 479px) {
      font-size: 12px;
    }
  }

  .price {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  .print {
    display: none;

    @media print {
      display: block;
    }
  }
</style>
