<template>
  <div id="app">
    <h2>Калькулятор расчета стоимости сэндвич-панелей</h2>

    <Calculator />
  </div>
</template>

<script>
import Calculator from '@/components/Calculator.vue';

export default {
  name: 'App',
  components: {
    Calculator,
  },
};
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 30px;
    line-height: 25px;
    text-transform: uppercase;
    color: #BF0A29;
    margin: 40px 0;

    @media print {
      font-size: 26px;
      line-height: 30px;
      margin: 15px 0;
    }

    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 26px;
    }
  }
</style>
