<template>
  <button class="btn">{{ name }}</button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
    box-sizing: border-box;
    background: linear-gradient(to bottom, #e13538, #ac1616);
    border-radius: 15px;
    min-width: 170px;
    width: auto;
    height: 48px;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    padding: 0 20px;
    font-weight: 400;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      background: #9b0a0b;
      box-shadow: inset -5px -5px 15px #7b0809;
    }

    @media (max-width: 479px) {
      font-size: 14px;
      padding: 0 10px;
    }
  }
</style>
