<template>
  <div class="block">
    <div class="props-list no-print">
      <template v-for="(item, index) in props">
        <div class="props-list__item" :key="index">
          {{ item.name }}

          <template v-if="item.type === 'select'">
            <Select
              v-model="userValue[item.code]"
              :options="propsOptions[item.code]"
              :disabled="item.disabled"
              @input="changeValueProp(index + 1)"
            />
          </template>

          <template v-else>
            <Input
              v-model="userValue[item.code]"
              :disabled="item.disabled"
            />
          </template>
        </div>
      </template>

      <div class="props-list__item">
        Количество, в м.кв.

        <Input
          v-model="quantity"
          type="number"
          @input="showResult = false"
        />
      </div>
    </div>

    <template v-if="arFilterPropsEmpty.length === 0 && !showResult && +quantity > 0">
      <Btn name="Рассчитать" class="no-print" @click.native.prevent="calcPrice()"/>
    </template>

    <template v-if="showResult">
      <ResultBlock
        :props="arUserPropsSelected"
        :price="totalPrice"
      />

      <div class="btn-block no-print">
        <FormEmailBlock
          :props="arUserPropsSelected"
          :price="totalPrice"
        />

        <PrintResult />
      </div>

      <FormBlock
        :props="arUserPropsSelected"
        :price="totalPrice"
        class="no-print"
      />
    </template>
  </div>
</template>

<script>
import Select from '@/components/Select.vue';
import Input from '@/components/Input.vue';
import Btn from '@/components/Btn.vue';
import ResultBlock from '@/components/ResultBlock.vue';
import PrintResult from '@/components/PrintResult.vue';
import FormEmailBlock from '@/components/FormEmailBlock.vue';
import FormBlock from '@/components/FormBlock.vue';

export default {
  name: 'Calculator',
  components: {
    Select,
    Input,
    Btn,
    ResultBlock,
    PrintResult,
    FormEmailBlock,
    FormBlock,
  },
  data() {
    return {
      props: [
        {
          name: 'Вид панели',
          type: 'select',
          code: 'type',
          disabled: false,
        },
        {
          name: 'Утеплитель',
          type: 'select',
          code: 'material',
          disabled: true,
        },
        {
          name: 'Ширина панели, мм',
          type: 'select',
          code: 'width',
          disabled: true,
        },
        {
          name: 'Толщина панели, мм',
          type: 'select',
          code: 'thick',
          disabled: true,
        },
      ],
      quantity: 0,
      price: 0,
      showResult: false,
      userValue: {
        type: null,
        material: null,
        width: null,
        thick: null,
      },
      propsOptions: {
        type: [],
        material: [],
        width: [],
        thick: [],
      },
      priceList: [
        // Цены на сэндвич-панели с утеплителем пенополистирол 1200 ММ
        {
          value: 1644,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '40',
          quantityMin: 0,
        },
        {
          value: 1664,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1684,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1724,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1764,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 1804,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 1858,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 1884,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 1911,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 1965,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2018,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2124,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2150,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1614,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '40',
          quantityMin: 500,
        },
        {
          value: 1634,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1654,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1694,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1734,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 1774,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 1828,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 1854,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 1881,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 1935,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 1988,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2094,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2120,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1584,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '40',
          quantityMin: 1000,
        },
        {
          value: 1604,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1624,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1664,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1704,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1744,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 1798,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 1824,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 1851,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 1905,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 1958,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2064,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2090,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1200',
          thick: '250',
          quantityMin: 1000,
        },

        // Цены на сэндвич-панели с утеплителем минеральная вата 1200 ММ
        {
          value: 1685,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '40',
          quantityMin: 0,
        },
        {
          value: 1721,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1760,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1827,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1898,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 1969,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 2039,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 2083,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 2123,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 2203,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2292,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2440,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2480,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1655,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '40',
          quantityMin: 500,
        },
        {
          value: 1691,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1730,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1797,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1868,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 1939,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 2009,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 2053,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 2093,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 2173,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 2262,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2410,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2450,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1625,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '40',
          quantityMin: 1000,
        },
        {
          value: 1661,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1700,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1767,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1838,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1909,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 1979,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 2023,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 2063,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 2143,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 2232,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2380,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2420,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1200',
          thick: '250',
          quantityMin: 1000,
        },

        // Цены на сэндвич-панели с утеплителем пенополистирол 1000 ММ
        {
          value: 1664,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '40',
          quantityMin: 0,
        },
        {
          value: 1684,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1704,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1744,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1784,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 1824,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 1878,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 1904,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 1931,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 1985,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2038,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2114,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2170,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1634,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '40',
          quantityMin: 500,
        },
        {
          value: 1654,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1674,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1714,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1754,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 1794,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 1848,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 1874,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 1901,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 1955,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 2008,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2084,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2140,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1604,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '40',
          quantityMin: 1000,
        },
        {
          value: 1624,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1644,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1684,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1724,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1764,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 1818,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 1844,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 1871,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 1925,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 1978,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2054,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2110,
          type: 'Стеновые',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 1000,
        },

        // Цены на сэндвич-панели с утеплителем минеральная вата 1000 ММ
        {
          value: 1705,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '40',
          quantityMin: 0,
        },
        {
          value: 1741,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1777,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1847,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1918,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 1989,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 2059,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 2103,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 2143,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 2223,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2312,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2460,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2500,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1675,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '40',
          quantityMin: 500,
        },
        {
          value: 1711,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1747,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1817,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1888,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 1959,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 2029,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 2073,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 2113,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 2193,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 2282,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2430,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2470,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1645,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '40',
          quantityMin: 1000,
        },
        {
          value: 1681,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1717,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1787,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1858,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1929,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 1999,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 2043,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 2083,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 2163,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 2252,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2400,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2440,
          type: 'Стеновые',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 1000,
        },

        // Цены на сэндвич-панели с утеплителем пенополистирол 1000 ММ
        {
          value: 1727,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1747,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1787,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1836,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 1889,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 1942,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 1968,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 1995,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 2049,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2102,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2208,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2234,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1697,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1717,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1757,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1806,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 1859,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 1912,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 1938,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 1965,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 2019,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 2072,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2178,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2204,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1667,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1687,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1727,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1776,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1829,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 1882,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 1908,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 1935,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 1989,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 2042,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2148,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2174,
          type: 'Кровельные',
          material: 'Пенополистирол',
          width: '1000',
          thick: '250',
          quantityMin: 1000,
        },

        // Цены на сэндвич-панели с утеплителем минеральная вата 1000 ММ
        {
          value: 1784,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 0,
        },
        {
          value: 1820,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 0,
        },
        {
          value: 1890,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 0,
        },
        {
          value: 1967,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 0,
        },
        {
          value: 2045,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 0,
        },
        {
          value: 2120,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 0,
        },
        {
          value: 2165,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 0,
        },
        {
          value: 2204,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 0,
        },
        {
          value: 2283,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 0,
        },
        {
          value: 2364,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 0,
        },
        {
          value: 2545,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 0,
        },
        {
          value: 2562,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 0,
        },

        {
          value: 1754,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 500,
        },
        {
          value: 1790,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 500,
        },
        {
          value: 1860,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 500,
        },
        {
          value: 1937,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 500,
        },
        {
          value: 2015,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 500,
        },
        {
          value: 2090,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 500,
        },
        {
          value: 2135,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 500,
        },
        {
          value: 2174,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 500,
        },
        {
          value: 2253,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 500,
        },
        {
          value: 2334,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 500,
        },
        {
          value: 2515,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 500,
        },
        {
          value: 2532,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 500,
        },

        {
          value: 1724,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '50',
          quantityMin: 1000,
        },
        {
          value: 1760,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '60',
          quantityMin: 1000,
        },
        {
          value: 1830,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '80',
          quantityMin: 1000,
        },
        {
          value: 1907,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '100',
          quantityMin: 1000,
        },
        {
          value: 1985,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '120',
          quantityMin: 1000,
        },
        {
          value: 2060,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '140',
          quantityMin: 1000,
        },
        {
          value: 2105,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '150',
          quantityMin: 1000,
        },
        {
          value: 2144,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '160',
          quantityMin: 1000,
        },
        {
          value: 2223,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '180',
          quantityMin: 1000,
        },
        {
          value: 2304,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '200',
          quantityMin: 1000,
        },
        {
          value: 2485,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '240',
          quantityMin: 1000,
        },
        {
          value: 2502,
          type: 'Кровельные',
          material: 'Минеральная вата',
          width: '1000',
          thick: '250',
          quantityMin: 1000,
        },
      ],
    };
  },
  computed: {
    arFilterProps() {
      return Object.keys(this.userValue);
    },
    arFilterPropsNoEmpty() {
      return this.arFilterProps.filter((item) => this.userValue[item] !== null);
    },
    arFilterPropsEmpty() {
      return this.arFilterProps.filter((item) => this.userValue[item] === null);
    },
    arPriceFiltered() {
      return this.priceList.filter((item) => {
        const find = this.arFilterPropsNoEmpty.every((code) => this.userValue[code] === item[code]);
        return find;
      });
    },
    arUserPropsSelected() {
      const arProps = this.props.map((item) => {
        const { name } = item;
        const value = this.userValue[item.code];

        return { name, value };
      });

      arProps.push({
        name: 'Количество,в м. кв.',
        value: this.quantity,
      });

      return arProps;
    },
    totalPrice() {
      return this.price * this.quantity;
    },
  },
  methods: {
    calcPrice() {
      const priceList = this.arPriceFiltered.map((item) => {
        const { quantityMin, value } = item;
        return { quantityMin, value };
      });

      priceList.sort((a, b) => {
        if (a.quantityMin > b.quantityMin) return 1;
        if (a.quantityMin < b.quantityMin) return -1;
        return 0;
      });

      this.price = priceList.reduce((price, item) => {
        const value = (this.quantity >= item.quantityMin) ? item.value : price;
        return value;
      }, 0);

      this.showResult = true;
    },
    changeValueProp(index) {
      if (this.props[index]) {
        this.setDisadledProps(index);
        this.setEmptyValueProps(index);
        this.setPropsOption(index);
      }

      this.showResult = false;
    },
    setDisadledProps(propIndex) {
      this.props = this.props.map((item, index) => {
        const prop = item;
        if (index > propIndex) {
          prop.disabled = true;
        } else if (index === propIndex) {
          prop.disabled = false;
        }

        return prop;
      });
    },
    setEmptyValueProps(propIndex) {
      this.props = this.props.map((item, index) => {
        const prop = item;
        if (index >= propIndex) {
          this.userValue[prop.code] = null;
        }

        return prop;
      });
    },
    setPropsOption(index) {
      const { code } = this.props[index];

      const options = this.arPriceFiltered.reduce((arProp, item) => {
        if (arProp.indexOf(item[code]) === -1) {
          arProp.push(item[code]);
        }

        return arProp;
      }, []);

      this.propsOptions[code] = options;
    },
  },
  created() {
    this.setPropsOption(0);
  },
};
</script>

<style lang="scss" scoped>
  .block {
    background-color: #E7E7E7;
    padding: 56px;
    margin-bottom: 40px;

    @media print {
      padding: 0;
      margin-bottom: 0;
    }

    @media (max-width: 479px) {
      padding: 30px;
    }
  }

  .props-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px 20px;
    justify-content: space-between;

    & + .btn {
      margin-top: 40px;

      @media (max-width: 479px) {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    @media (max-width: 1199px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 641px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 479px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .btn-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    @media (max-width: 1199px) {
      flex-wrap: wrap;

      & > div {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 479px) {
      justify-content: center;
    }
  }

  @media print {
    .no-print {
      display: none;
    }
  }
</style>
