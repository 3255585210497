<template>
  <input
    class="input"
    :value="value"
    :type="type"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    v-mask="mask"
    @input="input($event.target.value)"
  />
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      type: String,
      required: false,
      default: '',
    },
    pattern: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    input(event) {
      const value = (this.type === 'number') ? +event : event;

      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .input {
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 4px 30px 4px 10px;
    background: #fff;
    margin: 15px 0 0;
    outline: none;
  }
</style>
