<template>
  <div>
    <div class="block">
      <form class="form" @submit.prevent="submit">
        <div
          v-if="errorMessage"
          class="form__message form__message--error"
        >
          {{ errorMessage }}
        </div>

        <div
          v-if="successMessage"
          class="form__message form__message--success"
        >
          {{ successMessage }}
        </div>

        <div class="form__row">
          <template v-for="(item, index) in items">
            <div :key="index">
              {{ item.name }}

              <Input
                v-model="item.value"
                :type="item.type"
                :placeholder="item.placeholder"
                :required="item.required"
                :mask="item.mask"
              />
            </div>
          </template>

          <div class="form__btn">
            <Btn
              name="Отправить на email"
              v-if="!btnLoading"
            />

            <div class="form__preloader" v-if="btnLoading">
              <Preloader />
            </div>
          </div>
        </div>
      </form>

      <div class="form__note">
        Нажимая "Отправить на email" я соглашаюсь на обработку персональных данных
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Input from '@/components/Input.vue';
import Btn from '@/components/Btn.vue';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'FormEmailBlock',
  components: {
    Input,
    Btn,
    Preloader,
  },
  props: {
    props: {
      type: Array,
      required: true,
      default: () => [],
    },
    price: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      btnLoading: false,
      items: [
        {
          name: 'Ваш e-mail',
          placeholder: 'Ваш e-mail',
          required: true,
          type: 'text',
          value: '',
          mask: '',
          title: '',
          code: 'email',
        },
      ],
    };
  },
  computed: {
    checkForm() {
      const checkFields = this.items.every((item) => {
        if (item.required && item.value.length > 0) {
          return true;
        }

        if (!item.required) {
          return true;
        }

        return false;
      });

      return checkFields;
    },
    printPrice() {
      return this.price.toLocaleString();
    },
  },
  methods: {
    submit() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const action = '/ajax/formEmail.php';
        const props = {
          props: [],
        };

        this.items.forEach((item) => {
          props[item.code] = item.value;
        });

        this.props.forEach((item) => {
          props.props.push(`${item.name}: ${item.value}`);
        });

        props.props.push(`Стоимость: ${this.printPrice} руб.`);

        axios
          .post(action, props)
          .then((response) => {
            if (response.data.success) {
              this.successMessage = response.data.message;
            } else {
              this.errorMessage = response.data.message;
            }

            this.btnLoading = false;
          })
          .catch(() => {
            this.errorMessage = 'Произошла ошибка';
            this.btnLoading = false;
          });
      } else {
        this.errorMessage = 'Заполните обязательные поля';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .block {
    background: #FFFFFF;
    padding: 30px;
  }

  .form {

    &__row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px 20px;
      justify-content: space-between;
      align-items: end;

      @media (max-width: 641px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__btn {
      text-align: right;

      @media (max-width: 641px) {
        text-align: left;
      }

      @media (max-width: 479px) {
        text-align: center;
      }
    }

    &__preloader {
      min-width: 184px;
      display: inline-block;
    }

    &__note {
      text-align: right;
      font-size: 14px;
      margin-top: 20px;

      @media (max-width: 991px) {
        text-align: left;
        font-size: 12px;
      }

      @media (max-width: 479px) {
        text-align: center;
      }
    }

    &__message {
      margin-bottom: 5px;

      &--error {
        color: #EB1338;
      }

      &--success {
        color: #16a600;
      }
    }

    .input {
      background: #F6F6F6;
      height: 42px;
    }
  }
</style>
