<template>
  <Btn name="Распечатать результаты" @click.native.prevent="printResult()"/>
</template>

<script>
import Btn from '@/components/Btn.vue';

export default {
  name: 'PrintResult',
  components: {
    Btn,
  },
  methods: {
    printResult() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
  #calc {
    padding-top: 0;
  }

  @media print {
    body > section,
    body > nav,
    body > footer {
      display: none;
    }

    #calc {
      display: block;
    }
  }
</style>
