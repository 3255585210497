<template>
  <div class="select">
    <select
      :value="value"
      @change="change($event.target.value)"
      :disabled="disabled"
    >
      <option
        :value="null"
      >-- Выберите --</option>

      <template v-for="(item, index) in options">
        <option
          :key="index"
          :value="item"
        >{{ item }}</option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    change(event) {
      const value = (event === '') ? null : event;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .select {
    position: relative;
    margin-top: 15px;

    select {
      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      width: 100%;
      height: 34px;
      padding: 4px 30px 4px 10px;
      background: #fff;
      margin: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:active + .select:after {
        border-top-color: red;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 10px;
      border-top: 9px solid #ac1616;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      pointer-events: none;
    }
  }
</style>
